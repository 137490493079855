const successAction = (name) => `${name}_SUCCESS`;
const errorAction = (name) => `${name}_ERROR`;
const requestAction = (name) => `${name}_REQUEST`;

const mergeEntities = (entities) => {
  const results = [];
  entities.forEach((idList) => {
    idList.data.forEach((el) => {
      if (results.find((res) => el.id === res.id) === undefined) {
        results.push(el);
      }
    });
  });
  return results;
};

const createJsonPatchOp = (fieldName, value, op = "replace") => {
  return {
    op: op,
    path: `/${fieldName}`,
    value: value,
  };
};

export { mergeEntities, successAction, errorAction, requestAction, createJsonPatchOp };
