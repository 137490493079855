const isIterable = (obj) =>
  obj != null && typeof obj[Symbol.iterator] === "function";

const renderFormParams = (params) => {
  let formBody = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (isIterable(params[key]) && typeof params[key] !== "string") {
      params[key].forEach((param) => {
        formBody.append(key, param);
      });
    } else {
      formBody.append(key, params[key]);
    }
  });
  return formBody.toString();
};

class ApiError {
  constructor(title, data) {
    this.title = title;
    this.data = data;
  }
}

function getKeycloakExchangeFormBody(keycloak, cfg, audience) {
  let formBody = new URLSearchParams();
  const params = {
    client_id: cfg.keycloakClientId,
    grant_type: cfg.grantType,
    audience: audience,
    subject_token: keycloak.token,
    subject_token_type: cfg.subjectTokenType,
  };
  Object.keys(params).forEach((key) => formBody.append(key, params[key]));
  return formBody.toString();
}

/**
 * Given a query object, extracts the email from it if it has an email within the object.
 * @param {String} query with structure `fieldToSearch:filterApplied:value`.
 * Example where email would be taken from: `upn:Contains:example@cern.ch`
 * @returns {*|null}
 */
function extractEmailFromQuery(query) {
  // Check if query is an object and has a filter property which is an array
  if (
    typeof query === "object" &&
    Array.isArray(query.filter) &&
    query.filter.length > 0
  ) {
    // Split the first string in the filter array into components
    let parts = query.filter[0].split(":");

    // Check if there are at least three parts and the third part is an email
    if (parts.length === 3 && isEmail(parts[2], false)) {
      return parts[2];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

/**
 * Given a string checks if it fits into an email format pattern
 * @param {String} value to check if is or contains an email
 * @param {Boolean} fullFormat default set to true. If true valid pattern is only: `string@string.domain`
 * If false, valid pattern is: `string@string.domain`, and also: `string@string`
 * will be
 * @returns {boolean}
 */
function isEmail(value, fullFormat = true) {
  return fullFormat
    ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    : /^[^\s@]+@[^\s@]+(\.[^\s@]+)?$/.test(value);
}

const ApiVersions = Object.freeze({
  V1: "v1.0",
  V2: "v2.0",
});

const replaceApiVersion = (url, apiVersion) =>
  url ? url.replace(ApiVersions.V1, apiVersion) : undefined;

export {
  renderFormParams,
  ApiError,
  getKeycloakExchangeFormBody,
  extractEmailFromQuery,
  isEmail,
  ApiVersions,
  replaceApiVersion,
};
