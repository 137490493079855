// >>> Use these to initiate a new request
export const GET_IDENTITY = "GET_IDENTITY";
export const GET_IDENTITY_SUBSCRIPTIONS="GET_IDENTITY_SUBSCRIPTIONS";
export const GET_CURRENT_IDENTITY_SUBSCRIPTIONS = "GET_CURRENT_IDENTITY_SUBSCRIPTIONS"
export const CREATE_IDENTITY = "CREATE_IDENTITY";
export const DELETE_IDENTITY = "DELETE_IDENTITY";
export const SEARCH_IDENTITY_IDENTIFIER = "SEARCH_IDENTITY_IDENTIFIER";
export const UPDATE_IDENTITY = "UPDATE_IDENTITY";
export const TRANSFER_IDENTITY = "TRANSFER_IDENTITY";
export const CREATE_SECONDARY = "CREATE_SECONDARY";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const GET_MY_GROUPS = "GET_MY_GROUPS";
export const GET_IS_MEMBER_RECURSIVE = "GET_IS_MEMBER_RECURSIVE";
export const GET_GROUP_BY_ID = "GET_GROUP_BY_ID";
export const PATCH_GROUP_BY_ID = "PATCH_GROUP_BY_ID";
export const GET_ADMIN_GROUP_BY_ID = "GET_ADMIN_GROUP_BY_ID";
export const GET_ACCOUNT_BY_ID = "GET_ACCOUNT_BY_ID";
export const GET_CURRENT_PRIMARY_ACCOUNT = "GET_CURRENT_PRIMARY_ACCOUNT";
export const SET_PRIMARY_ACCOUNT = "SET_PRIMARY_ACCOUNT";
export const GET_CURRENT_IDENTITY_ACCOUNTS = "GET_CURRENT_IDENTITY_ACCOUNTS";
export const GET_ACCOUNT_PROVIDERS = "GET_ACCOUNT_PROVIDERS";
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_TO_APPROVE = "GET_REQUESTS_TO_APPROVE";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const DENY_REQUEST = "DENY_REQUEST";
export const APPROVE_REQUEST = "APPROVE_REQUEST";
export const SEARCH_IDENTITY = "SEARCH_IDENTITY";
export const SEARCH_ALL_IDENTITIES = "SEARCH_ALL_IDENTITIES";
export const RESOLVE_IDENTITY = "RESOLVE_IDENTITY";
export const RESOLVE_AUDITS_FOR_ENTITY = "RESOLVE_AUDITS_FOR_ENTITY";
export const GET_AUDIT_OPERATION_DETAILS = "GET_AUDIT_OPERATION_DETAILS";
export const GET_AUDIT_OPERATION_BY_ID = "GET_AUDIT_OPERATION_BY_ID";
export const RESOLVE_APPLICATION_MANAGER_IDENTITY =
  "RESOLVE_APPLICATION_MANAGER_IDENTITY";
export const ASSOCIATE_IDENTITY = "ASSOCIATE_IDENTITY";
export const SERVICE_DESK_ASSOCIATE_IDENTITY =
  "SERVICE_DESK_ASSOCIATE_IDENTITY";
export const SET_APPLICATION_STATUS = "SET_APPLICATION_STATUS";
export const ASSOCIATE_SOCIAL_IDENTITY = "ASSOCIATE_SOCIAL_IDENTITY";
export const DELETE_ACCOUNT_FROM_IDENTITY = "DELETE_ACCOUNT_FROM_IDENTITY";
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const EDIT_APPLICATION = "EDIT_APPLICATION";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const GET_APPLICATION = "GET_APPLICATION";
export const GET_APPLICATION_LIST_V2 = "GET_APPLICATION_LIST_V2";
export const GET_ALL_APPLICATIONS_V2 = "GET_ALL_APPLICATIONS_V2";
export const GET_ROLES = "GET_ROLES";
export const GET_REGISTRATIONS = "GET_REGISTRATIONS";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const REGISTER_APPLICATION = "REGISTER_APPLICATION";
export const DELETE_REGISTRATION = "DELETE_REGISTRATION";
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_FOR_IDENTITY = "GET_GROUPS_FOR_IDENTITY";
export const GET_GROUPS_FOR_ROLE = "GET_GROUPS_FOR_ROLE";
export const GET_ROLE_FOR_APPLICATION = "GET_ROLE_FOR_APPLICATION";
export const GET_LIFECYCLESETTINGS_FOR_APPLICATION = "GET_LIFECYCLESETTINGS_FOR_APPLICATION";
export const GET_APPLICATION_FOR_MANAGED_RESOURCE_TYPE = "GET_APPLICATION_FOR_MANAGED_RESOURCE_TYPE";
export const ADD_GROUP_TO_ROLE = "ADD_GROUP_TO_ROLE";
export const DELETE_GROUP_FROM_ROLE = "DELETE_GROUP_FROM_ROLE";
export const DISPLAY_SECRET = "DISPLAY_SECRET";
export const REGENERATE_SECRET = "REGENERATE_SECRET";
export const SEARCH_GROUP = "SEARCH_GROUP";
export const GET_IDENTITY_BY_ID = "GET_IDENTITY_BY_ID";
export const GET_OWN_IDENTITY_MEMBERSHIPS = "GET_OWN_IDENTITY_MEMBERSHIPS";
export const GET_OWN_IDENTITY_MEMBERSHIPS_RECURSIVE =
  "GET_OWN_IDENTITY_MEMBERSHIPS_RECURSIVE";
export const GET_IDENTITY_MEMBERSHIPS = "GET_IDENTITY_MEMBERSHIPS";
export const UNSUBSCRIBE_FROM_GROUPS = "UNSUBSCRIBE_FROM_GROUPS";
export const SUBSCRIBE_TO_GROUP = "SUBSCRIBE_TO_GROUP";
export const SUBSCRIBE_TO_GROUP_WITH_BODY_PARAMS =
  "SUBSCRIBE_TO_GROUP_WITH_BODY_PARAMS";
export const SUBSCRIBE_IDENTITY_TO_GROUP = "SUBSCRIBE_IDENTITY_TO_GROUP";
export const UNSUBSCRIBE_IDENTITY_FROM_GROUP =
  "UNSUBSCRIBE_IDENTITY_FROM_GROUP";
export const SUBSCRIBE_TO_GROUP_BY_EMAIL = "SUBSCRIBE_TO_GROUP_BY_EMAIL";
export const CREATE_STATIC_GROUP = "CREATE_STATIC_GROUP";
export const SEARCH_GROUP_IDENTIFIER = "SEARCH_GROUP_IDENTIFIER";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_GROUPS_I_OWN = "GET_GROUPS_I_OWN";
export const GET_GROUP_MEMBER_IDENTITIES = "GET_GROUP_MEMBER_IDENTITIES";
export const TRANSFER_GROUP_OWNER = "TRANSFER_GROUP_OWNER";
export const TRANSFER_APPLICATION_OWNER = "TRANSFER_APPLICATION_OWNER";
export const DELETE_GROUP_BY_ID = "DELETE_GROUP_BY_ID";
export const GET_GROUP_MEMBER_GROUPS = "GET_GROUP_MEMBER_GROUPS";
export const ADD_GROUP_MEMBER_GROUPS = "ADD_GROUP_MEMBER_GROUPS";
export const ADD_GROUP_MEMBER_IDENTITIES = "ADD_GROUP_MEMBER_IDENTITIES";
export const DELETE_GROUP_MEMBER_GROUPS = "DELETE_GROUP_MEMBER_GROUPS";
export const DELETE_GROUP_MEMBER_IDENTITIES = "DELETE_GROUP_MEMBER_IDENTITIES";
export const GET_GROUP_MEMBER_GROUPS_FOR_TREE_VIEW =
  "GET_GROUP_MEMBER_GROUPS_FOR_TREE_VIEW";
export const GET_GROUP_PARENT_FOR_TREE_VIEW = "GET_GROUP_PARENT_FOR_TREE_VIEW";
export const LOGOUT = "LOGOUT";
export const UPDATE_REGISTRATION = "UPDATE_REGISTRATION";
export const GET_ALL_MY_APP_ROLES = "GET_ALL_MY_APP_ROLES";
export const GET_LEVELS_OF_ASSURANCE = "GET_LEVELS_OF_ASSURANCE";
export const PATCH_IDENTITY = "PATCH_IDENTITY";
export const UPDATE_EXTERNAL_EMAIL = "UPDATE_EXTERNAL_EMAIL";
export const GET_ACCOUNTS_FOR_IDENTITY = "GET_ACCOUNTS_FOR_IDENTITY";
export const CREATE_MEMBERSHIP_RESTRICTION = "CREATE_MEMBERSHIP_RESTRICTION";
export const DELETE_MEMBERSHIP_RESTRICTION = "DELETE_MEMBERSHIP_RESTRICTION";
export const GET_MEMBERSHIP_RESTRICTION = "GET_MEMBERSHIP_RESTRICTION";

// Resource endpoints
export const GET_ALL_RESOURCES_OF_TYPE_FOR_ADMINISTRATOR = "GET_ALL_RESOURCES_OF_TYPE_FOR_ADMINISTRATOR";
export const GET_ALL_MY_RESOURCES = "GET_ALL_MY_RESOURCES";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const GET_RESOURCE_BY_ID_AND_TYPE = "GET_RESOURCE_BY_ID_AND_TYPE";
export const GET_ALL_MY_RESOURCES_OF_TYPE = "GET_ALL_MY_RESOURCES_OF_TYPE";

export const GET_DYNAMIC_GROUP_CRITERIA = "GET_DYNAMIC_GROUP_CRITERIA";
export const GET_ALLOWED_TOKEN_EXCHANGE_PERMISSIONS =
  "GET_ALLOWED_TOKEN_EXCHANGE_PERMISSIONS";
export const GET_GRANTED_TOKEN_EXCHANGE_PERMISSIONS =
  "GET_GRANTED_TOKEN_EXCHANGE_PERMISSIONS";

export const SEARCH_REGISTRATIONS = "SEARCH_REGISTRATIONS";
export const PUT_TOKEN_EXCHANGE_REQUEST_WITH_BODY_PARAMS =
  "PUT_TOKEN_EXCHANGE_REQUEST_WITH_BODY_PARAMS";
export const PUT_TOKEN_EXCHANGE_REQUEST = "PUT_TOKEN_EXCHANGE_REQUEST";
export const DELETE_TOKEN_EXCHANGE_PERMISSION =
  "DELETE_TOKEN_EXCHANGE_PERMISSION";

// User role endpoints
export const GET_ROLE_BY_NAME = "GET_ROLE_BY_NAME";
export const GET_GROUPS_FOR_ROLE_BY_ID = "GET_GROUPS_FOR_ROLE_BY_ID";

// Group scopes
export const GET_GROUP_SCOPES = "GET_GROUP_SCOPES";
export const GET_GROUP_SCOPE_BY_ID = "GET_GROUP_SCOPE_BY_ID";
export const GET_SCOPES_FOR_GROUP = "GET_SCOPES_FOR_GROUP";
export const ADD_GROUP_TO_SCOPE = "ADD_GROUP_TO_SCOPE";
export const REMOVE_GROUP_FROM_SCOPE = "REMOVE_GROUP_FROM_SCOPE";

// Account scopes
export const GET_ACCOUNT_SCOPES = "GET_ACCOUNT_SCOPES";
export const GET_ACCOUNT_SCOPE_BY_ID = "GET_ACCOUNT_SCOPE_BY_ID";
export const GET_SCOPES_FOR_ACCOUNT = "GET_SCOPES_FOR_ACCOUNT";
export const ADD_ACCOUNT_TO_SCOPE = "ADD_ACCOUNT_TO_SCOPE";
export const REMOVE_ACCOUNT_FROM_SCOPE = "REMOVE_ACCOUNT_FROM_SCOPE";

// Group role assignments
export const GET_GROUP_ROLE_ASSIGNMENTS = "GET_GROUP_ROLE_ASSIGNMENTS";

// Account management endpoints
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_INITIAL_PASSWORD = "SET_INITIAL_PASSWORD";
export const SERVICE_DESK_RESET_PASSWORD = "SERVICE_DESK_RESET_PASSWORD";
export const SERVICE_DESK_RESET_PASSWORD_EMAIL =
  "SERVICE_DESK_RESET_PASSWORD_EMAIL";
export const GET_OWNED_IDENTITIES = "GET_OWNED_IDENTITIES";
export const SD_RENAME_IDENTITY = "SD_RENAME_IDENTITY";
export const SD_EXTEND_ACCOUNT_EXPIRATION = "SD_EXTEND_ACCOUNT_EXPIRATION";
export const CHANGE_EDHPASSWORD = "CHANGE_EHDPASSWORD";
export const SERVICE_DESK_RESET_EDHPASSWORD = "SERVICE_DESK_RESET_EHDPASSWORD";
export const GET_EDHPASSWORD_HISTORY = "GET_EDHPASSWORD_HISTORY";

// Audit
export const SEARCH_AUDIT_LOGS = "SEARCH_AUDIT_LOGS";

// MFA settings
export const GET_MFA_SETTINGS_FOR_ACCOUNT = "GET_MFA_SETTINGS_FOR_ACCOUNT";
export const SET_MFA_SETTINGS_FOR_ACCOUNT = "SET_MFA_SETTINGS_FOR_ACCOUNT";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const BLOCK_IDENTITY = "BLOCK_IDENTITY";

// Mail Properties
export const GET_MAIL_PROPERTIES = "GET_MAIL_PROPERTIES";
export const SET_MAIL_PROPERTIES = "SET_MAIL_PROPERTIES";
export const SEARCH_MAIL_PROPERTIES_IDENTIFIER =
  "SEARCH_MAIL_PROPERTIES_IDENTIFIER";
export const UPDATE_MAIL_PROPERTIES = "UPDATE_MAIL_PROPERTIES";

// SCOPES
export const GET_PROTOCOL_SCOPES = "GET_PROTOCOL_SCOPES";

// Policy checks
export const CHECK_POLICY_SCRIPT = "CHECK_POLICY_SCRIPT";
