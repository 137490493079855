import {
  apiActionCreator,
  createApiCallWrapper,
  generateApiActions
} from "./actions";
import * as at from "./actions/types";
import { actionCalls, noJsonLoading } from "./actions/actionCalls";
import { AuthService } from "./services/AuthService";

const apiActions = generateApiActions(Object.values(at));

/**
 * Perform an API call with the given params
 * @param {import("redux").Dispatch} dispatch
 * @param {AuthService} svc
 * @param {object} params
 * @param {string} actionType
 */
const performApiCall = (svc, params, actionType) => {
  // No API call with this type exists
  const action = actionCalls[actionType];
  if (!action) {
    console.warn("Warning: unknown API call performed");
    return Promise.reject();
  }
  return action(svc, params);
};

let authService = null;

/**
 * Creates an authentication middleware using the provided
 * @param {AuthService} authService the authorization service to call
 */
export default cfg => store => next => action => {
  const apiCall = apiActions[action.type];
  const { keycloak } = store.getState();

  // None of the api call actions were passed, moving on...
  if (apiCall === undefined) {
    return next(action);
  }

  if (keycloak.authenticated && keycloak.instance.authServerUrl) {
    if (!authService) {
      authService = new AuthService(keycloak.instance, cfg);
    } else {
      authService.keycloak.token = keycloak.instance.token;
    }
  }

  // Keycloak issues, something along those lines
  if (!authService) {
    console.warn(
      "Keycloak instance not configured properly, cannot do API calls"
    );
    return next(action);
  }

  const [requestType, successType, errorType] = apiCall;
  const { payload, type } = action;
  // In order to start showing the loading spinners
  next(apiActionCreator(requestType));

  const wrapApiCall = createApiCallWrapper(next, successType, errorType);
  const loadJson = noJsonLoading.find(t => t === type) ? false : true;
  return wrapApiCall(
    () => performApiCall(authService, payload, type),
    loadJson
  );
};

export { apiActions };
