import { errorAction } from "./actions";
import { apiActions } from "./authServiceMiddleware";
import { notification } from "antd";

const successNotification = notification["success"];

// The reducer gets automatically generated based on the apiActions
const createErrorsMap = () => {
  let errorTypes = {};
  for (var actionType in apiActions) {
    if (apiActions.hasOwnProperty(actionType)) {
      const errorResponse = errorAction(actionType);
      errorTypes = { ...errorTypes, [errorResponse]: true };
    }
  }
  return errorTypes;
};

const errorTypes = createErrorsMap();

const handleError = (action) => {
  const errorResponseType = errorTypes[action.type];
  if (errorResponseType !== undefined) {
    const err = action.payload;
    let title = "Unknown error";
    let message = "An unknown error has occured.";

    if (err) {
      message = err.message;
      if (err.title) {
        title = err.title;
      }
      if (err.data) {
        if (err.data.message) {
          message = err.data.message;
        } else if (typeof err.data === "string") {
          message = err.data;
        }
      }
    }
    console.error(title + ":", message);
  }
};

/**
 * Create a new notification middleware with the predefined types of messages passed as a parameter
 */
export default (messages) => (store) => (next) => (action) => {
  const message = messages[action.type];
  handleError(action);
  if (message !== undefined) {
    successNotification({ message });
  }
  return next(action);
};
