const IdentitiesSearchProperties = {
  /**
   * Fields that will be retrieved for identities from the database.
   * @name queryFields
   * @type {string[]}
   */
  queryFields: [
    "personId",
    "upn",
    "displayName",
    "type",
    "owner",
    "supervisor",
    "birthDate",
    "securityIssues",
    "source",
    "blocked",
    "blockingReason",
    "creationTime",
    "primaryAccountEmail",
  ],
  /**
   * Filters that will be used to search identities in the database.
   * @name filterFields
   * @type {string[]}
   */
  filterFields: [
    "upn",
    "firstName",
    "lastName",
    "displayName",
    "personId",
    "primaryAccountEmail",
  ],
};

export { IdentitiesSearchProperties };
