class ApiCapabilitiesService {
  constructor(capabilitiesUrl) {
    this.loaded = false;
    this.capabilities = {};
    try {
      this.setup(capabilitiesUrl);
    } catch (e) {
      console.error("Error initializing capabilities service: ", e);
    }
  }

  async setup(url) {
    const response = await fetch(url);
    const data = await response.json();
    this.capabilities = data;
    this.loaded = true;
  }
}

export default ApiCapabilitiesService;
