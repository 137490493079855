import {
  successAction,
  errorAction,
  requestAction,
  mergeEntities,
  createJsonPatchOp,
} from "./helpers";

const apiActionCreator = (type, data) => {
  if (data !== undefined) {
    return { type: type, payload: { ...data } };
  }
  return { type: type };
};

const createResponse = (type, payload) => ({
  payload: payload,
  type: type,
});

const generateApiActions = (rootRequests) => {
  const generated = {};
  rootRequests.forEach((element) => {
    generated[element] = [
      `${element}_REQUEST`,
      `${element}_SUCCESS`,
      `${element}_ERROR`,
    ];
  });
  return generated;
};

/**
 * Wraps an API call into a
 * @param {import("redux").Dispatch} dispatch
 * @param {string} successType
 * @param {string} errorType
 */
const createApiCallWrapper = (dispatch, successType, errorType) => (
  wrapped,
  loadJson = true
) => {
  let response = wrapped();
  if (loadJson === true) {
    response = response.then((payload) => {
      // Handle promises containing either a Response or a JS Object
      if (typeof payload.json === 'function') {
        return payload.json();
      } else {
        return payload;
      }
    });
  }
  return response
    .then((data) => dispatch(createResponse(successType, data)))
    .catch((err) => dispatch(createResponse(errorType, err)));
};


export {
  createApiCallWrapper,
  generateApiActions,
  successAction,
  errorAction,
  requestAction,
  createResponse,
  apiActionCreator,
  mergeEntities,
  createJsonPatchOp,
};
